<style type="text/css">
@import "~@/assets/css/common.css";
</style>

<style scoped>
body {
  font-size: 16px;
}

.w-99 {
  width: 99%;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.style-set-box {
  border-bottom: unset;
}

.less-width .w50 {
  width: 40%;
}

.less-width .w50:nth-child(2n) {
  width: 60% !important;
}

.guding-top {
  width: 80%;
}

.top-height {
  /* max-height: 160px; */
  /* line-height: 160px; */
  display: flex;
}

.msgLabel {
  color: #000000;
}

.cjd_title {
  width: 100%;
  /* height: 50px; */
  line-height: 50px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #000000;
}

.bottom-info {
  max-height: 100px;
  padding-left: 20px;
}

.guding-col {
  flex-wrap: wrap;
  box-sizing: border-box;
}

.guding-col .msgLabel:nth-child(n-1) {
  border-bottom: unset;
}

.guding-img {
  width: 20%;
  height: 201px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: unset; */
  border: 1px solid #dfdfdf;
}

.style-right {
  background-size: cover;
  /*min-height: 500px;*/
  font-size: 18px;
  padding: 30px 25px 30px 80px;
  background-repeat: no-repeat;
}
.score-list .msgName {
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-border-bottom {
  border-bottom: 1px solid #dfdfdf;
}

  /* 新增防内容格式变形 */
.msgLabel {
  align-items: flex-start;
  min-height: 45px;
  height: initial
}
.style-name {
  flex: none;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import grade from "@/components/grade/grade";
import draggable from "vuedraggable";
import $ from "jquery";

// import $ from "jquery"
import { getgrade, getLibraryInfo, getTemplateInfo } from "@/api/grade";
import { getExamInfo, getUserExamOne } from "@/api/examination";
import { format } from "@/libs/common";
/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
    draggable,
    grade,
  },
  data() {
    return {
      title: "成绩查询列表",
      items: [
        {
          text: "成绩查询",
        },
        {
          text: "成绩查询结果",
          active: true,
        },
      ],
      query: "",
      examInfo: "",
      scoreData: {},
      gradeData: [
        "cj",
        "cjcllx",
        "dah",
        "jmcj",
        "km",
        "kmmc",
        "kmtg",
        "rdrq",
        "wjlbm",
        "wjrdiy",
        "ycl",
        "zkzh",
      ],
      gradeDataList: [],
      library: {},
      bgimgUrl: "",
      imgUrl: "",
      list1: [],
      sfxszp: false,
      sfhxbj: false,
      moduleType: "",
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      totalPage: 1,
      TemplateInfo: {},
      canfh:false
    };
  },
  methods: {
    //下载成绩单
    downloadScore() {
      this.$router.push({
        path: "/gradePrint",
        query: {
          bmh: this.query.bmh,
          cjkid: this.query.sid,
        },
      });

      // this.ExportSavePdf("成绩单", "gradePrint");
    },
    //成绩复核
    recheck() {
      this.$router.push({
        path: "/scoreCheck",
        query: {
          bmh: this.query.bmh,
          cjkid: this.query.sid,
        },
      });
    },
    dealData(data, index) {
      let obj = {};
      if (data["cj_" + index] != null) {
        this.gradeData.map((key) => {
          obj[key] = data[key + "_" + index];
        });
        this.gradeDataList.push(obj);
        this.dealData(data, index + 1);
      }
    },
    isTimeBetween(startTimeStr, endTimeStr) {
      // 注意：这里我们直接使用了空格分隔的日期时间字符串
      const startTime = new Date(startTimeStr).getTime() / 1000;
      const endTime = new Date(endTimeStr).getTime() / 1000;

      // 获取当前时间的时间戳（秒）
      const now = new Date().getTime() / 1000;

      // 判断当前时间是否在开始时间和结束时间之间（包括边界）
      return now >= startTime && now <= endTime;
    },
    getLibrary() {
      getLibraryInfo(this.query.sid).then((res) => {
        if (res.status) {

          if (
              this.isTimeBetween(res.data.fhkssj, res.data.fhjssj) &&
            res.data.sfyxfh == 1
          ) {
            this.canfh = true;
          }
          this.library = res.data;
          if (res.data.cjbjwj) {
            this.getFileUrl(res.data.cjbjwj, true).then((res) => {
              if (res.status) {
                this.bgimgUrl = res.data;
              }
            });
          }
          if (res.data.mbh) {
            this.getgradeTemplate(res.data.mbh);
          }
        }
      });
    },
    getGrade() {
      getgrade({
        cjkid: this.query.sid,
      }).then((res) => {
        if (res.status) {
          if (res.data) {
            // if(res.data.cscx){
            //   this.canfh = true
            // }
            this.dealData(res.data, 1);
            this.scoreData = res.data;
            let url = ''
            if (this.library.ksbmbh) {
              url = "bm_" + this.library.ksbmbh + "_" + res.data.zjhm;
            } else {
              url = "cjk_" + res.data.cjkid + "_" + res.data.zjhm;
            }
            if (this.TemplateInfo.sfxszp) {
              this.getFileUrl(url, true).then((res) => {
                if (res.status) {
                  this.imgUrl = res.data;
                }
                this.$nextTick(() => {
                  this.pageSign();
                  $(".guding-img img").attr("src", this.imgUrl);
                  $("#gradePrint").hide();
                  this.toPdfHtml();

                });
              });
            } else {
              this.$nextTick(() => {
                this.pageSign();
                $("#gradePrint").hide();
                this.toPdfHtml();

              });
            }
          }
        }
      });
    },
    getgradeTemplate(mbh) {
      getTemplateInfo(mbh).then((res) => {
        if (res.status) {
          this.TemplateInfo = res.data
          this.list1 = res.data.printTemplateFieldBeanList;
          this.sfxszp = res.data.sfxszp;
          this.sfhxbj = res.data.sfhxbj;
          this.moduleType = 0;
          if (this.sfhxbj) {
            this.moduleType = 1;
            this.pageHeight = Math.floor((1103 / 297) * 210);
          }
          this.searchBg(res.data.cjbjwj)

        }
      });
    },
    searchBg(cjbjwj){
      if(cjbjwj){
        this.getFileUrl(cjbjwj, true).then((res) => {
          if (res.status) {
            this.bgimgUrl = res.data;
            this.getGrade();
          }
        });
        return
      }
      this.getGrade();


    },
    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml() {
      $("#pdf").empty();
      $("#pdf").append(
        "<div class='pdf' id='pdf1' page='1' style='height:" +
          this.pageHeight +
          "px;position:relative'></div>"
      );
      // $("#pdf1").append(
      //   "<div class='page' style='height:30px'>" +
      //     this.totalPage +
      //     "/<span></span></div>"
      // );
      // 成绩单内容
      $("#gradePrint")
        .children()
        .children()
        .each((i, item) => {
          this.addItem(item, $(item).outerHeight(true), this.bgimgUrl);
        });
    },

    addItem(item, height, imgurl) {
      let oldNHum = this.pdfNum;
      let paddingHeight = 0;
      height = Number(height);
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.totalPage += 1;
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#pdf").append(
          "<div class='pdf' id='pdf" +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px;position:relative'></div>"
        );
        // $("#pdf" + this.pdfNum).append(
        //   "<div class='page' style='height:30px'>" +
        //     this.totalPage +
        //     "/<span></span></div>"
        // );
      }
      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item)[0].id == "scoreBottom" && this.pdfNum == oldNHum) {

        $(item).css("position", "absolute");
        $(item).css("bottom", "0");
      }
      $(item)
        .clone()
        .appendTo($("#pdf" + this.pdfNum));
      // $(".pdf").css("border", "1px #000000 solid")
      $(".pdf").css("padding", "0 25px 0 45px");
      if (imgurl) {
        $(".pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
  },
  created() {
    this.query = this.$route.query;
    this.getLibrary();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body score-print">
            <h4 class="card-title border-dash pb-3">
              <img
                src="@/assets/images/person/icon/if-paper.png"
                class="mr-2"
              />
              <span v-if="canfh">
                成绩查询结果如下，如果您对成绩结果有异议，可在规定时间内提交成绩复核申请，开放时间：{{
                  library.fhkssj
                }}至{{ library.fhjssj }}
              </span>
              <span v-else>此成绩不允许复核</span>
            </h4>
            <div
              id="pdf"
              style="margin: 0 auto"
              :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
            ></div>
            <div class="outerCotent mt-3 score-list mb-3 score-print" id="">
              <el-col :span="24" class="style-right" ref="main" id="mians">
                <grade
                  :list="list1"
                  :grade="scoreData"
                  :library="library"
                  :zpxs="sfxszp"
                  :sfhxbj="sfhxbj"
                ></grade>
                <div class="mt-3 flexList">
                  <button
                    type="button"
                    class="btn btn-info flexList mr-4 h30"
                    @click="downloadScore"
                  >
                    <img
                      src="@/assets/images/person/file-pdf.png"
                      class="mr-2"
                    />下载电子成绩单
                  </button>
                  <button
                    v-if="canfh"
                    type="button"
                    class="btn btn-danger flexList mr-2 h30"
                    @click="recheck"
                  >
                    <img
                      src="@/assets/images/person/upload-2-fill.png"
                      class="mr-2"
                    />提交成绩复核申请
                  </button>
                  <span class="grey-time" v-if="canfh"
                    >（成绩复核提交时间：{{ library.fhkssj }}至{{
                      library.fhjssj
                    }}）</span
                  >
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
